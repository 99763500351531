
    import __i18nConfig from '@next-translate-root/i18n'
    import __appWithI18n from 'next-translate/appWithI18n'
    import Footer from '../components/Footer';
import Navbar from '../components/Navbar';
import { useRouter } from 'next/router';
import '../styles/globals.css';
import AnnouncementBar from '../components/AnnouncementBar';
import useTranslation from 'next-translate/useTranslation';
import Link from 'next/link';
function MyApp({ Component, pageProps }) {
    const router = useRouter();
    const { t } = useTranslation('common');
    // Check if the current pathname is the homepage
    const isHomePage = router.pathname === '/';
    return (<div className={`min-h-screen flex flex-col ${isHomePage ? 'bg-[var(--primary)]' : 'bg-white'}`}>
            <AnnouncementBar>
                <Link href="/parking">
                    <p dangerouslySetInnerHTML={{
            __html: t('announcement-bar')
        }}></p>
                </Link>
            </AnnouncementBar>
            <Navbar />
            <div className="flex-grow flex">
                <div className={`container my-4 mx-auto flex-grow`}>
                    <Component {...pageProps}/>
                </div>
            </div>
            <Footer />
        </div>);
}
const __Next_Translate__Page__1922e7c4c0a__ = MyApp;

    export default __appWithI18n(__Next_Translate__Page__1922e7c4c0a__, {
      ...__i18nConfig,
      isLoader: true,
      skipInitialProps: true,
      loadLocaleFrom: __i18nConfig && __i18nConfig.loadLocaleFrom || ((l, n) => import(`@next-translate-root/locales/${l}/${n}`).then(m => m.default)),
    });
  